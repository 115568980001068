import { DataTable } from "primereact/datatable";
import AppLayout from "../components/layout/AppLayout";
import { Column } from "primereact/column";
import { useCallback, useMemo, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import useZascaTacticalHook from "../hooks/useZascaTacticalHook";
import { orderBy } from "lodash";
import { Checkbox } from 'primereact/checkbox';

const ZascaTactical = () => {
	const { productosAmazon, productosProv, isLoadingProductosAmazon, isLoadingProductosProv } = useZascaTacticalHook();
	const [filterRoi, setFilterRoi] = useState(true);
	
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

	const data = useMemo(
		() =>{
			let dat= productosProv?.map((p) =>{
			const pa = 	productosAmazon.find((pA) => (p.ean === pA.ean));
			const mejorPrecio = pa?.mejorPrecioFba?? pa?.mejorPrecio;
			const totalComisionFba = (pa?.comision/100*mejorPrecio+ pa?.costeFba)?.toFixed(2); 
			const beneficio = mejorPrecio - totalComisionFba - p.pvd * 1.21;
			const roi = 100*beneficio/(p.pvd*1.21);
			return {
				asin: pa.asin,
				ean: p.ean,
				nombre: p.nombre,
				proveedor: p.proveedor,
				nombreAmazon: pa?.amazonTitle,
				precioAmazon: pa?.mejorPrecio,
				precioFba: pa?.mejorPrecioFba ?? '******',
				categoria: p.categoria,
				comision: totalComisionFba,
				beneficio: beneficio?.toFixed(2),
				roiF: roi,
				roi: roi.toFixed(2),
				pvd: (p.pvd * 1.21)?.toFixed(2),
				stock: p.stock,
				rank1: parseInt(pa?.rank1?.split('|')[1], 10),
				rank2: parseInt(pa?.rank2?.split('|')[1], 10),
				rank3: parseInt(pa?.rank3?.split('|')[1], 10),
				fechaAct: new Date(p?.fechaAct)?.toLocaleDateString(),
				lastPrice: new Date(pa?.lastLowestPrice)?.toLocaleDateString(),
				shippingTimeFba: pa?.shippingTimeFba,
			};
		});
		if (filterRoi){
			dat = dat?.filter(d=>d.roiF>10);
		}
		return orderBy(dat, ['ean'], ['nombre']);
		},
			[filterRoi, productosAmazon, productosProv]
	);

	console.log(data?.length);

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const renderHeader = () => {
		return (
			<>
				<div className='flex justify-content-end'>
					<h4> ZascaTactical </h4>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							onChange={onGlobalFilterChange}
							placeholder='Buscar nombre/proveedor/ean..'
						/>
					</span>
					<div className='flex align-items-center'>
						<Checkbox onChange={() => setFilterRoi(!filterRoi)} checked={filterRoi} />
						<label className='ml-2'>Solo ROI positivo</label>
					</div>
				</div>
			</>
		);
	};

	const renderLink = useCallback((row,col)=>{
		const value= row[col.field];
		return <a href={`http://www.amazon.es/dp/${row.asin}`} target='_blank' rel="noreferrer">{value}</a>;
	},[]);


	const renderWuro = useCallback((row, col) => {
		const value = row[col.field];
		return (
			<span>
				{value} €
			</span>
		);
	}, []);

	const renderPorcentaje = useCallback((row, col) => {
		const value = row[col.field];
		return <span>{value} %</span>;
	}, []);

    return (
			<AppLayout>
				<div className='grid align-items-center justify-content-center  m-0 p-0'>
					<div className='col-11 p-3'>
						{isLoadingProductosProv && <h4>Cargando productos proveedor activos </h4>}
						{isLoadingProductosAmazon && <h4>Cargando productos AMAZON activos </h4>}
						<DataTable
							loading={isLoadingProductosProv || isLoadingProductosAmazon}
							value={data}
							stripedRows
							size='small'
							filters={filters}
							globalFilterFields={['codigoP', 'nombre', 'proveedor', 'ean']}
							header={renderHeader}
							paginator
							paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
							currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
							rows={10}
							style={{ fontSize: '10px' }}
							rowsPerPageOptions={[10, 20, 50]}
							scrollable
							scrollHeight='450px'
						>
							<Column header='Ean' field='ean' style={{ width: '90px' }} />
							<Column header='Nombre' field='nombre' style={{ width: '300px' }} />
							<Column header='Nombre Am' body={renderLink} field='nombreAmazon' style={{ width: '300px' }}></Column>
							<Column header='Proveedor' field='proveedor' sortable></Column>
							{/* <Column header='Cat' field='categoria' style={{ width: '350px' }} /> */}

							<Column
								header='Pvd'
								field='pvd'
								style={{ width: '150px', fontSize: '11px', fontWeight: 'bold' }}
								sortable
								body={renderWuro}
							></Column>
							<Column header='Stock' field='stock' sortable></Column>
							<Column
								header='Precio Amazon'
								field='precioAmazon'
								style={{ width: '150px' }}
								sortable
								body={renderWuro}
							></Column>
							<Column
								header='Precio FBA'
								field='precioFba'
								style={{ width: '150px' }}
								sortable
								body={renderWuro}
							></Column>
							<Column header='Comision' field='comision' style={{ width: '150px' }} sortable body={renderWuro}></Column>
							<Column
								header='Beneficio'
								field='beneficio'
								sortable
								style={{ fontSize: '12px', fontWeight: 'bolder' }}
								body={renderWuro}
							></Column>
							<Column
								header='ROI'
								field='roi'
								style={{ fontSize: '11px', fontWeight: 'bolder' }}
								sortable
								body={renderPorcentaje}
							></Column>
							<Column header='Rank1' field='rank1' sortable></Column>
							<Column header='Rank2' field='rank2' sortable></Column>
							<Column header='Rank3' field='rank3' sortable></Column>
							<Column header='Fecha price' field='lastPrice' sortable></Column>
						</DataTable>
					</div>
				</div>
			</AppLayout>
		);
};

export default ZascaTactical;