import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import {Dialog} from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import useProductosFetch from '../../hooks/useProductosFetch';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useImagenesFetch from '../../hooks/useImagenesFetch';
import { WS_IMG_URI } from '../../services/commonHelper';

import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import NuevoProductoDlg from './NuevoProductoDlg';

const NuevaLineaDlg = ({ open, onClose,addLinea }) => {
 const { productos } = useProductosFetch();
 const [ean,setEan] = useState('');
 const [nuevoProducto, setNuevoProducto] = useState(null);
 const [cantidad, setCantidad] = useState();
 const eanRef = useRef();
 const cantidadRef = useRef();

 useEffect(() => {
    eanRef?.current?.focus();
    setEan(null)
 }, []);
 
 const eanToUse = useMemo(() => (ean?.length < 13 ? `0${ean}` : ean), [ean]);
 useEffect(()=>{
    if (eanToUse?.length === 13){
        cantidadRef?.current?.focus();
    } 
 },[eanToUse]);

 const handleChangeEan = useCallback(e=>{
    const code = e.target.value;
    setEan(code);
 },[]);


 const producto = useMemo(()=>{
    const pr= productos?.find((p) => p.codigoP === eanToUse);
   return {
			...pr
		};
},[eanToUse, productos]);


 const { imagenesProducto } = useImagenesFetch({ id: producto?.id });

 const handleAddLinea = useCallback(()=>{   
    addLinea({
			ean: eanToUse,
			cantidad,
			idProducto: producto?.id,
			nombre: producto?.nombre,
			familia: producto?.familia,
			marca: producto?.marca,
			almacen: 1,
			fechaCreacion: new Date(),
		});
	setEan('');
	setCantidad(null);
	eanRef.current.focus();
 },[addLinea, cantidad, eanToUse, producto]);

 const footerContent = (
		<div>
			<Button label='Guardar' icon='pi pi-check ' onClick={handleAddLinea} />
			<Button label='Cancelar' icon='pi pi-times ' onClick={onClose}/>
		</div>
 );


 return (
		<Dialog
			header='Nueva linea'
			visible={open}
			style={{ width: '50vw' }}
			onHide={() => {
				if (!open) return;
				onClose(false);
			}}
			footer={footerContent}
		>
			<p className='m-0'>
				<strong className='mr-2'>EAN: </strong>
				<InputText placeholder autoFocus onChange={handleChangeEan} value={ean} ref={eanRef} />
				{!producto?.id && ean && <Button label='No encontrado- crear' onClick={() => setNuevoProducto(eanToUse)} />}

				<br />
				{imagenesProducto?.length > 0 && <Image src={`${WS_IMG_URI}/${imagenesProducto[0].id}`} height='100px' />}
				{producto?.nombre}
				<br />
				<strong className='mr-2'>Uds: </strong>
				<InputNumber
					value={cantidad}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && cantidad > 0) {
							handleAddLinea();
						}
					}}
					onChange={(e) => setCantidad(e.value)}
					ref={cantidadRef}
				/>
			</p>
			{nuevoProducto && <NuevoProductoDlg ean={eanToUse} open={nuevoProducto} onClose={() => setNuevoProducto(null)} />}
		</Dialog>
 );
};

NuevaLineaDlg.propTypes = 
{
    open: PropTypes.bool,
    onClose: PropTypes.func,
    addLinea: PropTypes.func
};

export default NuevaLineaDlg;