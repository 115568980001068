import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import AppMain from './AppMain';
import { addLocale } from 'primereact/api';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


 
//core
import "primereact/resources/primereact.min.css";
//theme
import 'primereact/resources/themes/saga-green/theme.css';
    
import 'primeicons/primeicons.css';   
import 'primeflex/primeflex.css';  
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const queryClient = new QueryClient({
		refetchOnWindowFocus: false,
		refetchOnmount: false,
		refetchOnReconnect: false,
		retry: false,
		defaultOptions: {
			queries: {
				staleTime: Infinity,
				cacheTime: 10 * 60 * 1000,
			},
		},
	});

	addLocale('es', {
		firstDayOfWeek: 1,
		dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
		dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: [
			'enero',
			'febrero',
			'marzo',
			'abril',
			'mayo',
			'junio',
			'julio',
			'agosto',
			'septiembre',
			'octubre',
			'noviembre',
			'diciembre',
		],
		monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
		today: 'Hoy',
		clear: 'Limpiar',
		dateFormat: 'dd/mm/yy',
	});

	return (
		<QueryClientProvider client={queryClient}>
			<div className='App'>
				<AppMain />
				<ToastContainer autoClose={2000} />
			</div>
			<ReactQueryDevtools initialIsOpen />
		</QueryClientProvider>
	);
}

export default App;
