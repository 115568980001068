
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { useAuthUser } from '../hooks/useAuthUser';

const LogoutButton = ({large}) => {
	const { logout } = useAuthUser();

	return (
		<Button
			icon='pi pi-power-off'
			rounded
			text
			outlined
			severity='secondary'
			size={large ? 'large ' : 'small'}
			aria-label='Bookmark'
			onClick={logout}
		/>
	);
};

LogoutButton.propTypes={
	large: PropTypes.bool
}
export default LogoutButton;
