import { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import useProductosFetch from '../hooks/useProductosFetch';
import useImagenesFetch from '../hooks/useImagenesFetch';
import { Image } from 'primereact/image';
import { setFicheroData, WS_IMG_URI } from '../services/commonHelper';
import Dropzone from 'react-dropzone';

const ImagenesProducto = ({ id }) => {
	const { productos } = useProductosFetch();
	const { imagenesProducto, saveImagen } = useImagenesFetch({id});
	const producto = useMemo(() => productos?.find((p) => p.id === parseInt(id, 10)), [productos, id]);

	const setFichero = useCallback((arrayb) => {
		saveImagen({ producto: id, imagen: arrayb });
	}, [id, saveImagen]);

	const onDropFichero = useCallback(
		(acceptedFiles) => {
			setFicheroData(acceptedFiles, setFichero);
		},
		[setFichero]
	);


	return (
		<>
			<h2 className='p-0 m-0'>Imágenes {producto?.nombre}</h2>

			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='field mb-4 col-12'>
					<label className='block text-900 font-medium mb-2'>Imagen</label>
					<div className='w-full border-1 border-dashed border-blue-600'>
						<Dropzone onDrop={onDropFichero}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p>Suelta imagen</p>
								</div>
							)}
						</Dropzone>
					</div>
				</div>
				{/* <div className='col-11'>
					<InputText onChange={(e) => setImagen(e.target.value)} value={imagen} style={{ width: '600px' }} />
					<Button onClick={saveImagenNueva} icon='pi pi-save' />
				</div> */}

				{imagenesProducto?.map((i) => (
						<div className='col-2 ' key={i.id}>
							{i.id}
							<br />
							{i.id && <Image src={`${WS_IMG_URI}/${i.id}`} alt='Image' width='250' preview />}

							{/* {i.url && <Image src={i.url} alt='Image' width='250' preview />} */}
						</div>
					))}
			</div>
		</>
	);
};

ImagenesProducto.propTypes = {
	id: PropTypes.number,
};
export default ImagenesProducto;
