import { useQuery } from "@tanstack/react-query";
import { getProductosAmazonService, getProductosProveedorService } from "../services/zascaSyncService";

const useZascaTacticalHook = ()=>{

     const { data: productosAmazon, isLoading: isLoadingProductosAmazon } = useQuery({
				queryKey: ['productosAmazon'],
				queryFn: async () => await getProductosAmazonService(),
			});

              const { data: productosProv, isLoading: isLoadingProductosProv } = useQuery({
								queryKey: ['productosProv'],
								queryFn: async () => await getProductosProveedorService(),
							});

							return { productosAmazon, productosProv, isLoadingProductosAmazon, isLoadingProductosProv };

};

export default useZascaTacticalHook;
