//export const WS_URI = 'http://zascalo.com:8092';
//export const WS_URI='http://zascalo.com:8091/zascaAlmacenREST'
export const WS_IMG_URI = 'https://estaticos1.zascalo.com/imagen/producto/';
export const WS_URI = 'https://estaticos1.zascalo.com';
//export const WS_URI='http://192.168.1.42:8091/zascaAlmacenREST';
export const WS_ZSYNCURI = 'https://estaticos2.zascalo.com/';
const arrayBufferToBase64 = (buffer) => {
	var binary = '';
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
};

	export const setFicheroData = (acceptedFiles, setter) => {
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();

			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');
			reader.onload = () => {
				// Do whatever you want with the file contents
				const binaryStr = reader.result;
				const base64String = arrayBufferToBase64(binaryStr);
				setter(base64String);
			};
			reader.readAsArrayBuffer(file);
		});
	};