import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getImagenesLiteByIdProductoService, getImagenesLiteService, saveImagenService } from "../services/almacenService";
import { toast } from "react-toastify";


const useImagenesFetch = ({id}={}) => {
	const queryClient = useQueryClient();
	const { data: imagenes } = useQuery({
		queryKey: ['imagenesLite'],
		queryFn: async () => await getImagenesLiteService(),
	});

	const { data: imagenesProducto } = useQuery({
		queryKey: ['imagenesProducto', id],
		queryFn: async () => await getImagenesLiteByIdProductoService(id),
		enabled: !!id,
	});

	const { mutate: saveImagen } = useMutation({
			mutationFn: saveImagenService,
			onSuccess: () => {
				toast('Imagen guardada correctamente');
				queryClient.invalidateQueries({ queryKey: ['imagenesLite'] });
				queryClient.invalidateQueries({ queryKey: ['imagenesProducto'] });
				window.movimientoGuardado = true;
			},
			onError: () => {
				toast('Error guardando imagen');
			},
		}); 

	return { imagenes,imagenesProducto, saveImagen };
};
export default useImagenesFetch;