import { useAuthUser } from "../hooks/useAuthUser";
import LogoutButton from "./LogoutButton";
import { Avatar } from 'primereact/avatar';

const UsuarioComponent = () =>{
    const { user } = useAuthUser();
    
    return (
			<div style={{ width: '400px' }} className='grid'>
				<div className='col-2'>
					<Avatar image={`data:image/png;base64,${user?.fotografia}`} shape='circle' size='large' />
				</div>
				<div className='col-4'>
					<div className='text-900 text-l font-medium mb-1'>{user?.nombre}</div>
					<div className='text-600 text-xs'>{user?.tipoUsuario}</div>
				</div>
				<div className='col-1'>
					<LogoutButton />
				</div>
			</div>
		);

};

export default UsuarioComponent;