import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import {  useContext,  useMemo } from 'react';
import InfoComponent from '../components/InfoComponent';
import AppContext from '../contexts/AppContext';

const RequireAuth = ({ children,roles }) => {
	const { user } = useContext(AppContext);

	const isValidUser = useMemo(() => {
		// if (user?.token) {
		// 	let decodedToken = jwtDecode(user?.token);
		// 	let currentDate = new Date();
		// 	// JWT exp is in seconds
		// 	if (decodedToken.exp * 1000 < currentDate.getTime()) {
		// 		axios.defaults.headers.common = null;
		// 		return false;
		// 	} else {
		// 		axios.defaults.headers.common = { Authorization: `${user?.token}` };
		// 		return true;
		// 	}
		// }
		// return false;
		return user?.usuario;
	}, [user]);

	// const intervalRef = useRef();

	// const getToken = useCallback(() =>{
	// 	refreshTokenService(user?.username).then(data =>{
	// 		setItem('user', JSON.stringify(data));
	// 		setUser(data);
	// 	});
	// } , [setItem, setUser, user]);

	// useEffect(() => {
	// 	if (isValidUser){
	// 		const interval = setInterval(() => getToken(), 3*60*1000);
	// 		intervalRef.current = interval;
	// 		return () => clearInterval(interval);
	// 	}
	// }, [getToken, isValidUser]);

	if (user && user?.tipoUsuario === 'TRABAJADOR') {
		return <InfoComponent title='Error' message='NO puede acceder a esta aplicación' error logoutB/>;
	}

	if (user && roles?.length && !roles?.includes(user?.tipoUsuario)) {
		return <InfoComponent title='Error' message='NO puede acceder a esta sección' error logoutB />;
	}

	if (!isValidUser) {
		return <Navigate to='/login' replace />;
	}

	return user ? children : <Navigate to='/login' replace />;
};

RequireAuth.propTypes = {
	children: PropTypes.any,
	roles: PropTypes.array
};
export default RequireAuth;
