import { useCallback, useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import useZascaEanHook from "../hooks/useZascaEanHook";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ConsultaEanPage = ()=>{
    const [ean, setEan] = useState('');
    const [eanBuscar, setEanBuscar]=useState(null);

    const { productoAmazon, productosProv, productoZascalo } = useZascaEanHook(eanBuscar);

    const renderFecha = useCallback(row=><span>
        {(new Date(row?.fechaAct)?.toLocaleDateString())}
    </span>,[]);

    return (
			<AppLayout>
				<h2 className='p-0 m-0'>
					Datos EAN:
					<InputText value={ean} onChange={(e) => setEan(e.target.value)} />
					<Button label='Buscar' onClick={() => setEanBuscar(ean)} />
				</h2>
				<h3> Producto Zascalo</h3>
				<div className='grid'>
					<div className='col-12'>
						<DataTable
							// loading={isLoadingProductos}
							value={productosProv}
							stripedRows
							size='small'
						>
							<Column header='ID' field='id' />
							<Column header='Ean' field='ean' style={{ width: '90px' }} />

							<Column header='Proveedor' field='proveedor' sortable />
							<Column header='Id interno' field='idInternoProveedor' />
							<Column header='Nombre' field='nombre' style={{ width: '300px' }} />
							<Column header='Categoria' field='categoria' />
							{/* <Column header='Desc corta' field='descripcionCorta' />
							<Column header='Desc' field='descripcion' /> */}
							<Column header='Stock' field='stock' sortable />
							<Column header='Pvd' field='pvd' sortable />
							<Column header='Peso' field='peso' />
							<Column header='Fecha' field='fechaAct' body={renderFecha} sortable />
						</DataTable>
					</div>
					<div className='col-6'>
						<Card title={ean}>
							<ul className='list-none p-0 m-0 flex-grow-1'>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Activo: </strong> {productoZascalo?.activo?'SI':'NO'}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Nombre: </strong> {productoZascalo?.nombre}{' '}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Marca: </strong> {productoZascalo?.marca}{' '}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Desc c: </strong>
										{productoZascalo?.descripcionCorta}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Desc l: </strong> {productoZascalo?.descripcionLarga}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Peso: </strong> {productoZascalo?.peso}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Fecha: </strong>
										{productoZascalo?.fecha}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Fecha act: </strong> {productoZascalo?.fechaAct}
									</span>
								</li>
							</ul>
						</Card>
					</div>
					<div className='col-6'>
						<Card title={productoAmazon?.asin}>
							<ul className='list-none p-0 m-0 flex-grow-1'>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Titulo: </strong> {productoAmazon?.amazonTitle}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Comisión: </strong> {productoAmazon?.comision}%
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Coste FBA: </strong> {productoAmazon?.costeFba}{' '}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Mejor precio: </strong>
										{productoAmazon?.mejorPrecio}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Mejor precio FBA: </strong> {productoAmazon?.mejorPrecioFba}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Shipping time: </strong> {productoAmazon?.shippingTimeFba}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Rank1: </strong>
										{productoAmazon?.rank1}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Rank2: </strong> {productoAmazon?.rank2}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Rank3: </strong> {productoAmazon?.rank3}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Last asin: </strong> {productoAmazon?.lastAsinDate}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Last price: </strong> {productoAmazon?.lastLowestPrice}
									</span>
								</li>
								<li className='flex align-items-center mb-3'>
									<i className='pi pi-check-circle text-green-500 mr-2'></i>
									<span className='text-900'>
										<strong>Last fees: </strong> {productoAmazon?.lastFees}
									</span>
								</li>
							</ul>
						</Card>
					</div>
				</div>
			</AppLayout>
		);
};

export default ConsultaEanPage;