import { DataTable } from "primereact/datatable";
import useFetchInventarios from "../hooks/useFetchInventarios";
import { Column } from "primereact/column";
import AppLayout from "../components/layout/AppLayout";
import { useCallback } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";

const InventariosPage = () => {
    const {inventarios, isLoadingInventarios} = useFetchInventarios({});
    const navigate = useNavigate();
    const renderFecha = useCallback((row) => {  
        return new Date(row.fechaCreacion).toLocaleDateString();
    },[]);

	const goTo = useCallback((e) => {		
		navigate(`/inventario/${e.data.id}`);
	},[navigate]);

    return (
			<AppLayout>
				<h2 className='p-0 m-0'>
					Inventarios
					<Button
						severity='help'
						label='Nuevo'
						icon='pi pi-plus'
						className='mr-2 ml-2'
						onClick={() => navigate('/inventariar')}
					/>
				</h2>

				<div className='grid'>
					<div className='col-12'>
						<DataTable loading={isLoadingInventarios} value={inventarios} stripedRows size='small'
							onRowClick={goTo}>
							<Column header='Fecha creacion' body={renderFecha} field='fechaCreacion' style={{ width: '300px' }} />
							<Column header='Estado' field='estado' style={{ width: '300px' }} />
						</DataTable>
					</div>
				</div>
			</AppLayout>
		);
};

export default InventariosPage