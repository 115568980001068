import { createContext } from 'react';


const AppContext = createContext({
	user: null,
	setUser: () => {},
	localSeleccionado: null,
	setLocalSeleccionado: () => {},
	
});

export default AppContext;
