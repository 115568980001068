import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {  getMovimientosAlmacenService, saveMovimientoService } from '../services/almacenService';
import { toast } from 'react-toastify';

const useMovimientosFetch = ({ id }) => {
	const queryClient = useQueryClient();

	const { data: movimientos, isLoading: isLoadingMovimientos } = useQuery({
		queryKey: ['movimientos', id],
		queryFn: async () => await getMovimientosAlmacenService(id),
        enabled:!!id
	});


	const { mutate: saveMovimiento } = useMutation({
		mutationFn: saveMovimientoService,
		onSuccess: () => {
			toast('Movimiento guardado correctamente');
			queryClient.invalidateQueries({ queryKey: ['movimientos'] });
			window.movimientoGuardado = true;
		},
		onError: () => {
			toast('Error guardando producto');
		},
	});

	return { movimientos, isLoadingMovimientos, saveMovimiento };

};
export default useMovimientosFetch;
