import PropTypes from 'prop-types';
import useZascaEanHook from '../../hooks/useZascaEanHook';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useProductosFetch from '../../hooks/useProductosFetch';
import { uniqBy } from 'lodash';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

const NuevoProductoDlg = ({ open, onClose, ean }) => {
	const { productoAmazon, productosProv, productoZascalo } = useZascaEanHook(ean);
	const { productos, familias, saveProducto } = useProductosFetch();

	const [data, setData] = useState(null);
	const producto = productoZascalo || productoAmazon || productosProv?.find((p) => p.ean === ean);
	useEffect(() => {
		if (!data && productoZascalo) {
			setData({
            familia: 1,
				nombre: productoZascalo.nombre,
				ean: productoZascalo.ean,
				codigoP: productoZascalo.ean,
				marca: productoZascalo.marca,
				descripcion: productoZascalo.descripcionCorta,
				descripcionLarga: productoZascalo.descripcionLarga,
				pvp: 17,
				pvpOferta: 15,
				miraviaNombreCategoria: 'Coleccionables y juegos',
				miraviaIdCategoria: 62207655,
			});
		} else if (!data && productoAmazon) {
			setData({
				familia: 1,
				nombre: productoAmazon.amazonTitle,
				ean: productoAmazon.ean,
				codigoP: productoAmazon.ean,
				descripcion: productoAmazon.amazonTitle,
				descripcionLarga: productoAmazon.amazonTitle,
				pvp: 17,
				pvpOferta: 15,
				miraviaNombreCategoria: 'Coleccionables y juegos',
				miraviaIdCategoria: 62207655,
			});
		} else if (productosProv?.length) {
			const productoP = productosProv[0];
			setData({
				familia: 1,
				nombre: productoP.nombre,
				marca: productoP.marca,
				codigoP: productoP.ean,
				descripcion: productoP.descripcionCorta,
				descripcionLarga: productoP.descripcionLarga,
				pvp: 17,
				pvpOferta: 15,
				miraviaNombreCategoria: 'Coleccionables y juegos',
				miraviaIdCategoria: 62207655,
			});
		}
	}, [productoAmazon, productosProv, productoZascalo]);

   console.log(data);

	const onChangeElement = useCallback(
		(name, value) => {
			const u = { ...producto };
			u[name] = value;
			setData(u);
		},
		[producto]
	);

	const familiasD = useMemo(
		() =>
			familias?.map((f) => ({
				label: f.nombre,
				value: f.id,
			})),
		[familias]
	);
	const marcasD = useMemo(
		() =>
			uniqBy(
				productos?.map((f) => ({
					label: f.marca,
					value: f.marca,
				})),
				'value'
			),
		[productos]
	);

	const onSaveProducto = useCallback(() => {
		saveProducto(data);
      onClose(false);
	}, [data, saveProducto, onClose]);

   console.log(data);

	return (
		<Dialog
			header='Nuevo producto'
			visible={open}
			style={{ width: '50vw' }}
			onHide={() => {
				if (!open) return;
				onClose(false);
			}}
		>
			<div className='col-12'>
				<div className='grid m-0 p-0 flex align-items-center bg-gray-100 m-1  '>
					<ul className='col-12 list-none p-0 m-0'>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Familia</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Dropdown
									onChange={(e) => onChangeElement('familia', e.value)}
									options={familiasD}
									value={data?.familia}
									placeholder='Seleccione familia'
									className='w-full'
									showClear
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Marca</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Dropdown
									onChange={(e) => onChangeElement('marca', e.value)}
									options={marcasD}
									value={data?.marca}
									placeholder='Seleccione marca'
									className='w-full'
									showClear
								/>
							</div>
						</li>

						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Código</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									className='w-full'
									rows={10}
									value={data?.codigoP}
									onChange={(e) => onChangeElement('codigoP', e.target.value)}
								/>
							</div>
						</li>

						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Nombre</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									className='w-full'
									value={data?.nombre}
									onChange={(e) => onChangeElement('nombre', e.target.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Descripcion</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputTextarea
									className='w-full'
									value={data?.descripcion}
									onChange={(e) => onChangeElement('descripcion', e.target.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Nombre C. Miravia</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									value={data?.miraviaNombreCategoria}
									onChange={(e) => onChangeElement('miraviaNombreCategoria', e.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>ID Cat Miravia</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									useGrouping={false}
									value={data?.miraviaIdCategoria}
									onChange={(e) => onChangeElement('miraviaIdCategoria', e.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Pvp</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									value={data?.pvp}
									onChange={(e) => onChangeElement('pvp', e.value)}
									mode='decimal'
									maxFractionDigits={2}
									locale='es-ES'
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Pvp Oferta</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									value={data?.pvpOferta}
									onChange={(e) => onChangeElement('pvpOferta', e.value)}
									mode='decimal'
									locale='es-ES'
									maxFractionDigits={2}
								/>
							</div>
						</li>
					</ul>
				</div>

				<div className='col-12 font-medium text-3xl text-900 mb-3'>
					<Button label='Guardar cambios' severity='danger' onClick={onSaveProducto} />
				</div>
			</div>
		</Dialog>
	);
};

NuevoProductoDlg.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	ean: PropTypes.string,
};

export default NuevoProductoDlg;
