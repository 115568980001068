import axios from 'axios';
import md5 from 'md5';
import { WS_URI } from './commonHelper';
import { toast } from 'react-toastify';

export const loginService = (user, password) => {
	const md5pass = md5(password);
	return axios.get(`${WS_URI}/login/login?usuario=${user}&pass=${md5pass}`).then((response) => response?.data);
};

export const getProductosService = () =>
	axios
		.get(`${WS_URI}/api/productos`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getFamiliasService = () =>
	axios
		.get(`${WS_URI}/api/familias`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const saveProductoService = (producto) =>
	axios
		.post(`${WS_URI}/api/saveProducto`, producto)
		.then((res) => res.data)
		.catch((err) => {
			toast.error('Error guardando productos ' + err, 10000);
		});

export const getEstadoAlmacenService = (id) =>
	axios
		.get(`${WS_URI}/api/estadoAlmacen?almacen=${id}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});
export const getMovimientosAlmacenService = (id) =>
	axios
		.get(`${WS_URI}/api/movimientosAlmacen?almacen=${id}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const saveMovimientoService = (movimiento) =>
	axios
		.post(`${WS_URI}/api/saveMovimiento`, movimiento)
		.then((res) => res.data)
		.catch((err) => {
			toast.error('Error guardando movimiento ' + err, 10000);
		});

export const saveImagenService = (imagen) =>
	axios
		.post(`${WS_URI}/saveImagenProducto`, imagen)
		.then((res) => res.data)
		.catch((err) => {
			toast.error('Error guardando imagen ' + err, 10000);
		});

export const getImagenesLiteService = () =>
	axios
		.get(`${WS_URI}/api/getImagenesLite`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo imagenes ' + err, 10000);
		});
export const getInventariosService = () =>
	axios
		.get(`${WS_URI}/getInventarios`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getLineasInventarioService = (id) =>
	axios
		.get(`${WS_URI}/getLineasInventario?inventario=${id}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const saveInventarioService = (imagen) =>
	axios
		.post(`${WS_URI}/saveInventario`, imagen)
		.then((res) => res.data)
		.catch((err) => {
			toast.error('Error guardando inv ' + err, 10000);
		});
export const getImagenesLiteByIdProductoService = (idProducto) =>
	axios
		.get(`${WS_URI}/getImagenesLiteByIDProducto?producto=${idProducto}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo imagenes ' + err, 10000);
		});