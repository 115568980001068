import PropTypes from 'prop-types';
import LogoutButton from '../auth/LogoutButton';

const InfoComponent = ({title, message,description,logoutB,error})=>{
return (
	<div className='surface-section px-4 py-8 md:px-6 lg:px-8'>
		<div className='grid'>
			<div className='col-12'>
				<div className='p-3 h-full'>
					<div className='shadow-2 p-3 h-full flex flex-column surface-card' style={{ borderRadius: '6px' }}>
						<div className='text-700 text-center'>
							<div className='text-blue-600 font-bold mb-3'>
								{error && <i className='pi pi-ban' style={{ fontSize: '10rem' }}></i>}
								{!error && <i className='pi pi-exclamation-triangle' style={{ fontSize: '10rem' }}></i>}
							</div>
							<div className='text-900 font-bold text-5xl mb-3'>
								{title}
								<br />
								{message}
							</div>
							{description && <div className='text-700 text-2xl mb-5'>{description}</div>}
							{logoutB && <LogoutButton large />}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
};

export default InfoComponent;

InfoComponent.propTypes = {
	message: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	logoutB: PropTypes.bool,
    error: PropTypes.bool
};