import axios from 'axios';
import { toast } from 'react-toastify';
import { WS_ZSYNCURI } from './commonHelper';

export const getProductosAmazonService = () =>
	axios
		.get(`${WS_ZSYNCURI}productosAmazonActualizados`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getProductosProveedorService = () =>
	axios
		.get(`${WS_ZSYNCURI}productosProveedorActualizados`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getProductoAmazonEanService = (ean) =>
	axios
		.get(`${WS_ZSYNCURI}productoAmazonEan?ean=${ean}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

		export const getProductosProveedorEanService = (ean) =>
			axios
				.get(`${WS_ZSYNCURI}productosProveedorEan?ean=${ean}`)
				.then((res) => res?.data)
				.catch((err) => {
					toast.error('Error obteniendo productos ' + err, 10000);
				});

				export const getProductoZascaloEanService = (ean) =>
					axios
						.get(`${WS_ZSYNCURI}productoZascalo?ean=${ean}`)
						.then((res) => res?.data)
						.catch((err) => {
							toast.error('Error obteniendo productos ' + err, 10000);
						});
