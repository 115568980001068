import { useQuery } from '@tanstack/react-query';
import { getProductoAmazonEanService, getProductoZascaloEanService, getProductosProveedorEanService } from '../services/zascaSyncService';

const useZascaEanHook = (ean) => {
	const { data: productoAmazon } = useQuery({
		queryKey: ['productoAmazon',ean],
		queryFn: async () => await getProductoAmazonEanService(ean),
		enabled: !!ean
	});

	const { data: productosProv } = useQuery({
		queryKey: ['productosProveedorEan',ean],
		queryFn: async () => await getProductosProveedorEanService(ean),
		enabled: !!ean
	});

	const { data: productoZascalo } = useQuery({
		queryKey: ['productoZascalo', ean],
		queryFn: async () => await getProductoZascaloEanService(ean),
		enabled: !!ean,
	});

	return { productoAmazon, productosProv, productoZascalo };
};

export default useZascaEanHook;
