import { useCallback, useState } from 'react';
import AppLayout from '../components/layout/AppLayout';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate, useParams } from 'react-router';
import { orderBy } from 'lodash';
import useMovimientosFetch from '../hooks/useMovimientosFetch';
import { Button } from 'primereact/button';
import useImagenesFetch from '../hooks/useImagenesFetch';
import { Image } from 'primereact/image';
import moment from 'moment/moment';
import { WS_IMG_URI } from '../services/commonHelper';

const MovimientosPage = () => {
	const { id } = useParams();
	const { movimientos, isLoadingMovimientos } = useMovimientosFetch({ id });
	const { imagenes } = useImagenesFetch({});
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const navigate = useNavigate();

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const renderHeader = () => {
		return (
			<div className='flex justify-content-end'>
				<span className='p-input-icon-left'>
					<i className='pi pi-search' />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Buscar nombre/zona..' />
				</span>
			</div>
		);
	};

	const renderFecha = useCallback((row) =>  moment(new Date(row.fecha)).format('DD-MM-yyy'), []);

    const renderEntrada = useCallback((row) =>row.entrada?'Entrada':'Salida', []);

	const renderImage = useCallback(
		(row) => {
			const imagesP = imagenes?.filter((i) => i.producto === row.producto.id);
			if (imagesP?.length) {
					return <Image src={`${WS_IMG_URI}/${imagesP[0].id}`} alt='Image' height='60' preview key={imagesP[0].id} />;
			}
			return null;
		},
		[imagenes]
	);

	return (
		<AppLayout>
			<h2 className='p-0 m-0'>
				Movimientos {id === '1' ? 'Zascalo' : 'Outlet'}
				<Button
					icon='pi pi-plus'
					rounded
					aria-label='Nuevo'
					tooltip='Nuevo producto'
					className='ml-2'
					onClick={() => {
						navigate('/nuevoMovimiento');
					}}
				/>
			</h2>

			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='col-11 p-3'>
					<DataTable
						loading={isLoadingMovimientos}
						value={orderBy(movimientos, ['fecha'], ['desc'])}
						stripedRows
						size='small'
						filters={filters}
						globalFilterFields={['producto.codigoP', 'producto.nombre']}
						header={renderHeader}
						paginator
						rows={100}
						rowsPerPageOptions={[5, 10, 25, 50,100]}
					>
						<Column header='Img' body={renderImage} />
						<Column header='Ean' field='producto.codigoP' style={{ width: '90px' }} />
						<Column header='Nombre' field='producto.nombre' sortable></Column>
						<Column header='Entrada' field='entrada' style={{ width: '90px' }} body={renderEntrada} sortable></Column>
						<Column header='Ud.' field='unidades' style={{ width: '90px' }} sortable></Column>
						<Column header='Precio' field='precio' style={{ width: '90px' }} sortable></Column>
						<Column header='Destino' field='obra' sortable></Column>
						<Column header='Observaciones' field='observaciones' sortable></Column>
						<Column header='Fecha.' field='fecha' style={{ width: '120px' }} sortable body={renderFecha}></Column>
					</DataTable>
				</div>
			</div>
		</AppLayout>
	);
};

export default MovimientosPage;
