import { useMemo } from 'react';
import useImagenesFetch from '../hooks/useImagenesFetch';
import useProductosFetch from '../hooks/useProductosFetch';
import useFetchAlmacen from '../hooks/useFetchAlmacen';
import {ProgressSpinner} from 'primereact/progressspinner';
import { WS_IMG_URI } from '../services/commonHelper';

import { Image } from 'primereact/image';

const CatalogoPage = () => {
	const { productos, isLoadingProductos } = useProductosFetch();
	const { imagenes } = useImagenesFetch({});
	const { estadoAlmacen: almacenZ, isLoadingEstadoAlmacen } = useFetchAlmacen({ id: 1 });
	const datos = useMemo(
		() =>
			productos
				?.map((p) => ({
					...p,
					almacenado: almacenZ?.find((a) => a.producto.id === p.id)?.unidades ?? 0,
					imagenes: imagenes?.filter((i) => i.producto === p.id),
				}))
				?.filter((p) => p?.almacenado > 0),
		[almacenZ, imagenes, productos]
	);

	return (
		<div className='surface-ground'>
			{(isLoadingProductos || isLoadingEstadoAlmacen) && <ProgressSpinner />}
			<div className='grid'>
				{datos?.map((d) => (
					<div key={d.id} className='col-4 p-1'>
						<div className='surface-card shadow-2 border-round p-1'>
							<div className='flex flex-column align-items-center surface-border pb-3'>
								{d.imagenes?.length > 0 && <Image src={`${WS_IMG_URI}/${d.imagenes[0].id}`} 
                                
                                className='mb-3 w-9' height='300px' />}
								{/* <img src='assets/images/blocks/products/bracelet.jpg'alt='Eso' /> */}
								<span className='text-base text-900 font-medium mb-2'>{d.nombre}</span>
								<span className='text-sm
								 text-800 block mb-3 font-semibold line-through'>{d.pvp} €</span>
								<span className='text-lg text-red-800 text-800 block mb-3 font-semibold'>{d.pvpOferta} €</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CatalogoPage;
