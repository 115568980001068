import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFamiliasService, getProductosService, saveProductoService } from "../services/almacenService";
import { toast } from "react-toastify";

const useProductosFetch = () =>{
	const queryClient = useQueryClient();

    const { data: productos, isLoading: isLoadingProductos } = useQuery({
        queryKey:['productos'], 
        queryFn: async () => await getProductosService()
    });

    const { data: familias, isLoading: isLoadingFamilias } = useQuery({
			queryKey: ['familias'],
			queryFn: async () => await getFamiliasService(),
		});

    const { mutate: saveProducto } = useMutation({
			mutationFn: saveProductoService,
			onSuccess: () => {
				toast('Producto guardado correctamente');
				queryClient.invalidateQueries({ queryKey: ['productos'] });
				window.productoGuardado = true;
			},
			onError: () => {
				toast('Error guardando producto');
			},
		});
  
    return { productos, isLoadingProductos, familias, isLoadingFamilias, saveProducto };
}

export default useProductosFetch;