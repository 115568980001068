import { useQuery } from "@tanstack/react-query";
import { getEstadoAlmacenService } from "../services/almacenService";

const useFetchAlmacen = ({id})=>{
     const { data: estadoAlmacen, isLoading: isLoadingEstadoAlmacen } = useQuery({
        queryKey:['almacen', id], 
        queryFn: async () => await getEstadoAlmacenService(id)
    });

    return {estadoAlmacen, isLoadingEstadoAlmacen};
};

export default useFetchAlmacen;
