import { useCallback, useEffect, useMemo, useState } from "react";
//import useProductosFetch from "../hooks/useProductosFetch";
import AppLayout from "../components/layout/AppLayout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import NuevaLineaDlg from '../components/inventarios/NuevaLineaDlg';
import useImagenesFetch from "../hooks/useImagenesFetch";
import { WS_IMG_URI } from "../services/commonHelper";
import { Image } from "primereact/image";
import { useNavigate, useParams } from "react-router";
import useFetchInventarios from "../hooks/useFetchInventarios";
import { saveInventarioService } from "../services/almacenService";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const InventariarPage = () => {
    const [lineas, setLineas ] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
   const {imagenes} = useImagenesFetch();
   const {id} = useParams();
   const { inventarios, lineasInventario, isLoadingLineasInventario } = useFetchInventarios({ id });
    const queryClient = useQueryClient();
    const navigate = useNavigate();

   useEffect(() => {
			if (lineasInventario) {
				setLineas(lineasInventario);
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [lineasInventario, isLoadingLineasInventario]);

    const inventario = useMemo(()=>inventarios?.find(i=>i.id===parseInt(id,10)),[id, inventarios]);

   const addLinea= useCallback((linea)=>{
    setLineas([...lineas, linea]);
   },[lineas]);

   const renderImage = useCallback(
			(row) => {
				const imagesP = imagenes?.filter((i) => i.producto === row.idProducto);
				if (imagesP?.length) {
					return <Image src={`${WS_IMG_URI}/${imagesP[0].id}`} alt='Image' height='60' preview key={imagesP[0].id} />;
				}
				return null;
			},
			[imagenes]
		);

    const  renderBorrarLinea = useCallback((row)=>{
        if (inventario?.estado !== 'FINALIZADO')    
        return <Button icon='pi pi-trash' onClick={()=>   setLineas(lineas.filter(l=>l!==row))} />;
        return null;
     
    },[inventario?.estado, lineas]);

    const saveInventario = useCallback(async ()=>{
        if (inventario){
            await saveInventarioService({
                inventario:{
                    ...inventario  ,
                    almacen: 1,
                    fechaModificacion: new Date(),
                },              
                lineas
            });
            toast.info('Inventario guardado');
			queryClient.invalidateQueries({ queryKey: ['inventarios'] });
			queryClient.invalidateQueries({ queryKey: ['inventarioLineas',id] });
        }else{
           const inv= await saveInventarioService({inventario: {
							almacen: 1,
							fechaCreacion: new Date(),
							fechaModificacion: new Date(),
                            estado: 'ABIERTO',
                             },
							lineas,
						});
                         toast.info('Inventario guardado');
                        navigate(`/inventario/${inv.inventario.id}`);
        }
      
    },[id, inventario, lineas, navigate, queryClient]);

    const finalizarInventario = useCallback(()=>{       
        const lineasFinales = [];
        lineas.forEach(l=>{
            if (!lineasFinales?.find(l2=>l2.ean === l.ean)){
            const allLineasP = lineas?.filter((l2)=>l2.ean === l.ean);
            lineasFinales.push({
                ...l,
                cantidad: allLineasP.reduce((acc, l2)=>acc+l2.cantidad,0)
            });
        }
        });
        console.log(lineasFinales)
    },[lineas]);

    return (
			<AppLayout>
				<h2 className='p-0 m-0'>
					Inventariar
					{inventario?.estado !== 'FINALIZADO' && (
						<Button
							severity='help'
							label='Nueva linea'
							icon='pi pi-plus'
							className='mr-2 ml-2'
							onClick={() => setOpenDialog(true)}
						/>
					)}
					{inventario?.estado !== 'FINALIZADO ' && (
						<Button
							severity='success'
							label='Guardar inventario'
							icon='pi pi-save'
							onClick={saveInventario}
							className='mr-2 ml-2'
						/>
					)}
					{inventario?.estado !== 'FINALIZADO' && (
						<Button
							severity='info'
							label='Finalizar '
							icon='pi pi-check'
							className='mr-2 ml-2'
							onClick={finalizarInventario}
						/>
					)}
				</h2>

				<div className='grid'>
					<div className='col-12'>
						<DataTable
							// loading={isLoadingProductos}
							value={lineas}
							stripedRows
							size='small'
							scrollable
							scrollHeight='calc(100vh - 180px)'
						>
							<Column header='id' field='id' style={{ width: '120px' }} />
							<Column body={renderImage} style={{ width: '120px' }} />
							<Column header='Ean' field='ean' style={{ width: '90px' }} />
							<Column header='Nombre' field='nombre' style={{ width: '300px' }} />
							<Column header='Precio' field='precio' style={{ width: '300px' }} />
							<Column header='Cantidad' field='cantidad' style={{ width: '300px' }} />
							<Column header='' body={renderBorrarLinea} style={{ width: '50px' }} />
						</DataTable>
						{openDialog && <NuevaLineaDlg open={openDialog} onClose={() => setOpenDialog(null)} addLinea={addLinea} />}
					</div>
				</div>
			</AppLayout>
		);

};

export default InventariarPage;