import { useQuery } from "@tanstack/react-query";
import { getInventariosService, getLineasInventarioService } from "../services/almacenService";

const useFetchInventarios = ({ id }) => {
	const { data: inventarios, isLoading: isLoadingInventarios } = useQuery({
		queryKey: ['inventarios'],
		queryFn: async () => await getInventariosService(),
	});

	const { data: lineasInventario, isLoading: isLoadingLlneasInventario } = useQuery({
		queryKey: ['inventarioLineas', id],
		queryFn: async () => await getLineasInventarioService(id),
		enabled: !!id,
	});

    return {inventarios, lineasInventario, isLoadingInventarios, isLoadingLlneasInventario};

};

export default useFetchInventarios;
