import { useAuthUser } from '../hooks/useAuthUser';
import {InputText} from 'primereact/inputtext';
import { Password } from 'primereact/password'; 
import { Button } from 'primereact/button';
import loginImage from '../assets/login.png';
import { useState } from 'react';
const LoginPage = () => {
	const { login } = useAuthUser();
	const [user,setUser] = useState('');
	const [password, setPassword] = useState('');
	const handleLogin = () => {
		login(user,password);
	};

	return (
		<div className='align-items-center flex justify-content-center lg:px-8 md:px-6 px-4 py-8  ng-star-inserted h-full'
			>
			<div className='p-4 shadow-4 border-round w-full lg:w-6 bg-gray-200'>
				<div className='text-center mb-5'>
					<img src={loginImage} alt='Image' height='100' className='mb-3 br-5' />
					<div className='text-900 text-3xl font-medium mb-3'>Bienvenid@</div>
				</div>
				<div>
					<label className='block text-900 font-medium mb-2'>Datos de entrada</label>
					<InputText
						id='email1'
						type='text'
						placeholder='Usuario'
						className='w-full '
						value={user}
						onChange={(e) => setUser(e.target.value)}
					/>
					<label className='block text-900 font-medium mb-2 pt-3'></label>
					<Password
						id='password1'
						type='password'
						value={password}
						placeholder='Contraseña'
						feedback={false}
						style={{ paddingBottom: '2%', width: '100%', minWidth: '100%' }}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<Button label='Entrar' icon='pi pi-caret-right' size='medium' severity='success' onClick={handleLogin} />
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
