import { InputText } from 'primereact/inputtext';
import AppLayout from '../components/layout/AppLayout';
import useProductosFetch from '../hooks/useProductosFetch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import useMovimientosFetch from '../hooks/useMovimientosFetch';
import useFetchAlmacen from '../hooks/useFetchAlmacen';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

const MovimientoFormPage = () => {
	const [searchParams] = useSearchParams();
	const [ean, setEan] = useState();
	const { productos } = useProductosFetch();
	const [movimiento, setMovimiento] = useState({
		almacen: 1,
		entrada: searchParams?.get('entrada') === 'true',
		fecha: new Date(),
	});

	useEffect(()=>{
		if (searchParams?.get('producto') && !movimiento?.producto && productos){
			const mov = cloneDeep(movimiento);
			mov.producto = productos?.find(p=>searchParams.get('producto')===p.codigoP);
			setMovimiento(mov);
		}
	},[movimiento, productos, searchParams]);

	const { saveMovimiento } = useMovimientosFetch({});
	const { estadoAlmacen: almacenZ } = useFetchAlmacen({ id: 1 });
	const { estadoAlmacen: almacenO } = useFetchAlmacen({ id: 2 });
	// const productoWS = useMemo(() => productos?.find((p) => p.id === parseInt(id, 10)), [id, productos]);

	const navigate = useNavigate();

	// useEffect(() => {
	// 	setProducto(cloneDeep(productoWS));
	// }, [productoWS]);

	const onChangeElement = useCallback(
		(name, value) => {
			const u = { ...movimiento };
			u[name] = value;
			setMovimiento(u);
		},
		[movimiento]
	);
	const onChangeProducto = useCallback(
		(value) => {
			const u = { ...movimiento };
			u.producto = { id: value };
			setMovimiento(u);
		},
		[movimiento]
	);

	const productosL = useMemo(
		() =>
			productos?.map((f) => ({
				label: `${f.codigoP} -- ${f.nombre}`,
				value: f.id,
			})),
		[productos]
	);

	const onSaveProducto = useCallback(() => {
		if (
			!movimiento.producto ||
			!movimiento.unidades ||
			!movimiento.obra ||
			!movimiento.fecha ||
			!movimiento.almacen
		) {
			toast.error('TODOS LOS CAMPOS SON OBLIGATORIOS');
		} else {
			let producto = null;
			if (movimiento.almacen === 1) {
				producto = almacenZ?.find((p) => p.producto.id === movimiento.producto.id);
			} else {
				producto = almacenO?.find((p) => p.producto.id === movimiento.producto.id);
			}
			if (producto && producto.unidades < movimiento.unidades && !movimiento.entrada) {
				toast.error('Solo hay ' + producto.unidades + ' en almacen');
			} else  {
				saveMovimiento(movimiento);
			}
		}
	}, [almacenO, almacenZ, movimiento, saveMovimiento]);

	if (window.movimientoGuardado) {
		window.movimientoGuardado = false;
		navigate('/movimientos/' + movimiento.almacen);
	}

	const eanToUse = useMemo(() => (ean?.length < 13 ? `0${ean}` : ean), [ean]);
	useEffect(() => {
		if (eanToUse?.length === 13) {
			setMovimiento({ ...movimiento, producto: productos?.find((p) => p.codigoP === eanToUse) });
		}
	}, [eanToUse, movimiento, productos]);

	return (
		<AppLayout>
			<h2 className='p-0 m-0'>Nuevo movimiento</h2>
			<div className='lg:px-8 md:px-6 px-1 py-1 surface-section ng-star-inserted'>
				<div className='grid m-0 p-0 flex align-items-center bg-gray-100 m-1  '>
					<ul className='col-12 list-none p-0 m-0'>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Almacen</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Dropdown
									filter
									onChange={(e) => onChangeElement('almacen', e.value)}
									options={[
										{ label: 'Zascalo', value: 1 },
										{ label: 'Outlet', value: 2 },
									]}
									value={movimiento?.almacen}
									placeholder='Seleccione almacen'
									className='w-full'
									showClear
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Producto</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Dropdown
									filter
									onChange={(e) => onChangeProducto(e.value)}
									options={productosL}
									value={movimiento?.producto?.id}
									placeholder='Seleccione producto'
									className='w-full'
									showClear
								/>
								<InputText value={ean} autoFocus onChange={e=>setEan(e.target.value)} />
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Entrada/salida</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<SelectButton
									value={movimiento?.entrada}
									onChange={(e) => onChangeElement('entrada', e.value)}
									options={[
										{ label: 'Entrada', value: true },
										{ label: 'Salida', value: false },
									]}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Precio</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									value={movimiento?.precio}
									onChange={(e) => onChangeElement('precio', e.value)}
									maxFractionDigits={2}
									mode='decimal'
									locale='es-ES'
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Unidades</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									value={movimiento?.unidad}
									onChange={(e) => onChangeElement('unidades', e.value)}
									maxFractionDigits={2}
									mode='decimal'
									locale='es-ES'
								/>
								(Quedan {almacenZ?.find((p) => p.producto.id === movimiento?.producto?.id)?.unidades} en almacen)
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Fecha</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Calendar value={movimiento?.fecha} onChange={(e) => onChangeElement('fecha', e.value)} locale='es' />
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>{movimiento.entrada ? 'Origen' : 'Destino'}</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									className='w-full'
									value={movimiento?.obra}
									onChange={(e) => onChangeElement('obra', e.target.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Observaciones</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									className='w-full'
									value={movimiento?.observaciones}
									onChange={(e) => onChangeElement('observaciones', e.target.value)}
								/>
							</div>
						</li>
					</ul>
					<div className='col-12 font-medium text-3xl text-900 mb-3'>
						<Button label='Guardar cambios' severity='danger' onClick={onSaveProducto} />
					</div>
					<p></p>
				</div>
			</div>
		</AppLayout>
	);
};

export default MovimientoFormPage;
